import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/tenzsoftcloud.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Web() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
              <h2 class="mt-4">Cloud Management and Support Services</h2>
            </div>
            <h5 className="mb-5">
              With the cloud computing solutions from Tenz Soft Company, set off
              on a path to efficiency and scalability. Our proficiency in cloud
              architecture guarantees a smooth shift to the cloud, improving
              communication, security, and accessibility. We streamline your
              business operations, from data storage to application deployment,
              giving you a strong platform for growth in the constantly changing
              digital market.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img
                src={service}
                class="img-fluid"
                alt="nettroller cloud services"
              />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Applications Dev</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Integrations Services</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>DevOps</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Software Services</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Migration</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
