import React from 'react'
import Navbar from "../components/NavBar"
import Footer from "../components/Footer"
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
import { Link } from "react-router-dom";

export default function Pricing() {
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
        <Navbar/>
          <main id="main">
<div class="section-header mt-3">
      <h3 class="text-success mt-5">Web Packages</h3>

    </div>
<section id="pricing" class="pricing">
  <div class="container" data-aos="fade-up">
    <div class="row gy-4">

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Service Website-1</h3>
          <h4 class="text-success"><sup>$</sup>300-1000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>5 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>Theme Customization</li>
            <li><i class="bi bi-check"></i>Blog Setup</li>
            <li><i class="bi bi-check"></i>Contact Form </li>
          </ul>
         
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Service Website-2</h3>
          <h4 class="text-success"><sup>$</sup>500-2000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>12 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>Theme Customization</li>
            <li><i class="bi bi-check"></i>Blog Setup</li>
            <li><i class="bi bi-check"></i>Contact Form </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Ecommerce Website-3</h3>
          <h4 class="text-success"><sup>$</sup>1000-3000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>15 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>11 Categories</li>
            <li><i class="bi bi-check"></i>100 Products</li>
            <li><i class="bi bi-check"></i>Contact Form </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>
</main>
<div class="section-header">
      {/* <span>Mobile App Packages</span> */}
      <h3 class="text-success">App Packages</h3>

    </div>
<section id="pricing" class="pricing">
      <div class="container" data-aos="fade-up">

        <div class="row gy-4">

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="pricing-item">
              <h3>Basic</h3>
              <h4 class="text-success"><sup>$</sup>500-2000<span></span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 1 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li class="na"><i class="bi bi-x"></i> <span>App submission</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>App icon</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>Ad network integration</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>Splash screen</span></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="pricing-item featured">
              <h3>Standard</h3>
              <h4 class="text-success"><sup>$</sup>2000-5000<span></span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 2 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li><i class="bi bi-check"></i> App submission</li>
                <li><i class="bi bi-check"></i>Splash screen</li>
                <li><i class="bi bi-check"></i> App icon</li>
                <li class="na"><i class="bi bi-x"></i> <span>Ad network integration</span></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
            <div class="pricing-item">
              <h3>Premium</h3>
              <h4 class="text-success"><sup>$</sup>5000-10000<span> </span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 2 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li><i class="bi bi-check"></i> App submission</li>
                <li><i class="bi bi-check"></i>Splash screen</li>
                <li><i class="bi bi-check"></i> App icon</li>
                <li><i class="bi bi-check"></i> Ad network integration</li>
                </ul>
              {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="section-header">
      {/* <span>SEO Packages</span> */}
      <h3 class="text-success">SEO Packages</h3>

    </div>
    <section id="horizontal-pricing" class="horizontal-pricing pt-0">
  <div class="container" data-aos="fade-up">
  <section id="pricing" class="pricing">
  <div class="container" data-aos="fade-up">
    <div class="row gy-4">

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Location wise SEO Packages</h3>
          <h4 class="text-success"><sup>$</sup>200<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Local SEO package Target cities and states such as London ,England</li>
            <li><i class="bi bi-check"></i>10 keywords target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i>2nd month to continue off-page 100 backlinks and social media 100 post per month</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 " data-aos="fade-up" data-aos-delay="200">
        <div class="pricing-item ">
          <h3>Web Traffic SEO packages</h3>
          <h4 class="text-success"><sup>$</sup>300<span> / month</span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Website organic visits approximately 259 to 500</li>
            <li><i class="bi bi-check"></i> 10 keywords target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i>2nd month to continue off-page 400 backlinks and social media 100 post per month</li>

          </ul>
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
        <div class="pricing-item">
          <h3>Ecommerce SEO Packages</h3>
          <h4 class="text-success"><sup>$</sup>500<span> / month</span></h4>
          <ul>
            <li><i class="bi bi-check"></i>420 to 840 ecommerce Product base SEO Packages</li>
            <li><i class="bi bi-check"></i> 80 keyword target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i> 2nd month to continue off-page 400 backlinks and social media 100 post per month</li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>
    <div class="section-header">
      <h3 class="text-success">Marketing Packages</h3>

    </div>



    <section id="horizontal-pricing " class="horizontal-pricing pt-0">
  <div class="container" data-aos="fade-up">
      <div class="row gy-4 pricing-item  mt-4" data-aos="fade-up" data-aos-delay="200">
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h3>Social Media Package</h3>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h4 class="text-success"><sup>$</sup>99<span> / month</span></h4>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <ul>
          <li><i class="bi bi-check"></i>15 hours per month</li>
          <li><i class="bi bi-check"></i> <strong>60 facebook post</strong></li>
          <li><i class="bi bi-check"></i> 60 Instagram post</li>
        </ul>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <div class="text-center"><a href="#" class="buy-btn text-decoration-none bg-success">Buy Now</a></div>
      </div>
    </div>
    </div>
    </section>



  </div>
</section>
<Footer/>
    </div>
  )
}
