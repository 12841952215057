import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/sce.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Seo() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title mt-2" data-aos="fade-up">
              <h2>Search Engine Optimization Services</h2>
            </div>
            <h5 className="mb-5">
              With the strategic SEO services offered by Tenz Soft Company, you
              can fully realize the potential of your online presence. Our
              skilled SEO experts use cutting-edge methods to raise the exposure
              of your website, increasing organic traffic and search engine
              rankings. We make sure that your brand shines in the digital
              sphere, drawing in the correct audience and ensuring long-term
              success online by customizing our strategy to your particular
              aims.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Local SEO Service</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>SEO</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex">
                    <i class="bi bi-check"></i>
                    <h3>Google Penalty Recovery</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>SEO audit</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Link Building</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Content Marketing</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <Footer />
    </div>
  );
}
