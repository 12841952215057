import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/tenzsoftdm.jpg";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Digital() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title mt-2" data-aos="fade-up">
              <h2>Digital Marketing Services</h2>
            </div>
            <h5 className="mb-5">
              Tenz Soft Company offers complete digital marketing solutions that
              will help you maximize your online effect and expand your digital
              footprint. Our specialists use the newest trends and statistics in
              everything from Search Engine Optimization (SEO) to focused social
              media campaigns to increase the visibility of your business. With
              our data-driven and ROI-focused digital marketing services, you
              may get a competitive edge in the online market and produce
              noticeable results.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="nettroller" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Social Media Optimization</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Google Ads</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Facebook Ads</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Instagram Ads</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Youtube Ads</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
