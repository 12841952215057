import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/tenzsoftgd.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Graphic() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title mt-2" data-aos="fade-up">
              <h2>Graphic UI/UX Services</h2>
            </div>
            {/* <p class="mt-3">Graphic UI/UX Services</p> */}

            <h5 className="mb-4">
              Use the imaginative graphic design services offered by Tenz Soft
              Company to leave a memorable impression. Our skilled staff turns
              ideas into powerful visual assets, such as memorable logos and
              persuasive marketing materials. We make sure every design element
              is in line with your brand identity since we recognize the
              importance of aesthetics in brand communication and want to make a
              lasting impression on your audience.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="nettroller" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Logo Design</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flyer & Brochures</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>UI/UX design</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Visual Content</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
  );
}
