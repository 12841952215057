import React from 'react';
import Navbar from "../components/NavBar"
import Footer from "../components/Footer"
import "../styles/Projects.css"
import { useLocation } from "react-router-dom"
import { useEffect } from 'react';

const projectData = [
  { image: require("../images/software1.png"), title: "Sales CRM", category: "filter-business" },
  { image: require("../images/app2.jpg"), title: "Geofencing App", category: "filter-ecommerce" },
  { image: require("../images/app3.jpg"), title: "Retail Store", category: "filter-business" },
  { image: require("../images/app4.jpg"), title: "Tour Recommendation App", category: "filter-ecommerce" },
  { image: require("../images/app5.jpg"), title: "Spy Camera App", category: "filter-ecommerce" },
  { image: require("../images/app6.jpg"), title: "Task Automation", category: "filter-business" },
];

const webProjectData = [
  { image: require("../images/cheezi.jpg"), title: "Cheezi Bite", category: "filter-business" },
  { image: require("../images/dh.jpg"), title: "Dream Home", category: "filter-ecommerce" },
  { image: require("../images/dubai.jpg"), title: "Best Dubai Landscape", category: "filter-business" },
  { image: require("../images/pe.jpg"), title: "Pakistan Electronics", category: "filter-ecommerce" },
  { image: require("../images/mf.png"), title: "Molty Foam", category: "filter-ecommerce" },
  { image: require("../images/kb.jpg"), title: "Khan Balti House", category: "filter-business" },
  { image: require("../images/choice.jpg"), title: "Best Choice Landscape", category: "filter-business" },
  { image: require("../images/tikka.png"), title: "Tikka Tavern", category: "filter-business" },
  { image: require("../images/df.jpg"), title: "Dura Foam", category: "filter-ecommerce" },
];

const digitalMarketingData = [
  { image: require("../images/alanas.jpg"), title: "Alanas Promising Projects", category: "filter-business" },
  { image: require("../images/bracknell.jpg"), title: "Herbies Pizza", category: "filter-ecommerce" },
  { image: require("../images/herbs.jpg"), title: "Herbs Cafe", category: "filter-business" },
  { image: require("../images/tandoori.jpg"), title: "Herbs Tandoori", category: "filter-ecommerce" },
  { image: require("../images/sew.jpg"), title: "Sew Seam", category: "filter-ecommerce" },
  { image: require("../images/kids.jpg"), title: "Kids Coding", category: "filter-business" },
  { image: require("../images/cheezy.jpg"), title: "Cheezi Bite", category: "filter-business" },
  { image: require("../images/dmg.jpg"), title: "Digital Marketing Gujranwala", category: "filter-business" },
  { image: require("../images/hooria.jpg"), title: "Hooria Collection", category: "filter-ecommerce" },
];

const Projects = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Navbar />

      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <p className="text-success">Softwares & Mobile App Projects</p>
          </header>

          <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {projectData.map((project, index) => (
              <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${project.category}`}>
                <div className="portfolio-wrap">
                  <img src={project.image} className="img-fluid" alt={project.title} />
                  <div className="portfolio-info">
                    <h4>{project.title}</h4>
                  </div>
                </div>
                <h3 className="text-center m-2 text-success fw-bold text-decoration-underline">{project.title}</h3>
              </div>
            ))}
          </div>

        </div>

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <p className="text-success">Our Web Projects</p>
          </header>

          <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {webProjectData.map((project, index) => (
              <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${project.category}`}>
                <div className="portfolio-wrap">
                  <img src={project.image} className="img-fluid" alt={project.title} />
                  <div className="portfolio-info">
                    <h4>{project.title}</h4>
                  </div>
                </div>
                <h3 className="text-center m-2 text-success fw-bold text-decoration-underline">{project.title}</h3>
              </div>
            ))}
          </div>

        </div>

        <div className="container" data-aos="fade-up">

          <header className="section-header">
            <p className="text-success">Digital Marketing Clients</p>
          </header>

          <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {digitalMarketingData.map((project, index) => (
              <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${project.category}`}>
                <div className="portfolio-wrap">
                  <img src={project.image} className="img-fluid" alt={project.title} />
                  <div className="portfolio-info">
                    <h4>{project.title}</h4>
                  </div>
                </div>
                <h3 className="text-center m-2 text-success fw-bold text-decoration-underline">{project.title}</h3>
              </div>
            ))}
          </div>

        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Projects;
