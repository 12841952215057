import React from 'react';
import review1 from "../images/review1.jpg";
import review2 from "../images/review2.avif";
import review3 from "../images/review3.jpg";
const TestimonialItem = ({ quote, image, name, role }) => {
  return (
    <div className="swiper-slide">
      <div className="testimonial-item">
        <p>
          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
          {quote}
          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
        </p>
        <img src={image} className="testimonial-img" alt="" />
        <h3>{name}</h3>
        <h4>{role}</h4>
      </div>
    </div>
  );
};

const TestimonialsSection = () => {
  const testimonialsData = [
    {
      quote:
        "Choosing Tenz Soft was a game-changer for our business. Their software's reliability and adaptability have streamlined our operations, making us more efficient and competitive",
      image: review2,
      name: 'Sartaj Babbloo',
      role: 'Founder - Tikka Tavern',
    },
    {
      quote:
        'Tenz Soft has truly revolutionized our workflow with their innovative software solutions. Their commitment to user-friendly interfaces and cutting-edge technology sets them apart in the industry.',
      image: review3,
      name: 'Saul Goodman',
      role: 'CEO & Founder',
    },
    {
      quote:
        'Exceptional experience with Tenz Soft! Their software not only meets our expectations but exceeds them. The perfect blend of functionality and simplicity.',
      image: review1,
      name: 'Shehzad Ali',
      role: 'Project Coordinator',
    },
  ];

  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">
        <header className="section-header">
          <p className="text-warning">Our Clients</p>
        </header>
        <div className="row">
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div
                className="testimonials-slider swiper"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="swiper-wrapper">
                  <TestimonialItem {...testimonial} />
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
