import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/tenzsoftsd.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Software() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title mt-2" data-aos="fade-up">
              <h2>Large Scale Softwares we develop</h2>
            </div>
            <h5 className="mb-5">
              Use the custom software development services offered by Tenz Soft
              Company to completely change the face of your company. Our skilled
              developers are experts at crafting custom apps that improve
              efficiency, stimulate creativity, and streamline processes. We
              make sure your software fits perfectly with your particular
              business demands by utilizing the newest technology, opening the
              door for long-term success and growth.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="nettroller" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Smart Factory Management</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Supply Chain Management</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Inventory and Asset Management</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Field Service</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Government Portal</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
