import React from 'react'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import Home from "./pages/Home"
import About from "./pages/About"
import Services from "./pages/Services"
import Projects from "./pages/Projects"
import Team from "./pages/Team"
import Contact from "./pages/Contact"
import Franchise from "./pages/Franchise"
import ServicesInfo from "./pages/servicesInfo"
import Web from "./pages/web"
import Mobile from "./pages/app"
import Cloud from "./pages/cloud"
import Software from "./pages/software"
import Seo from "./pages/seo"
import Digital from "./pages/digital"
import Graphic from "./pages/graphic"
import Content from "./pages/content"
import Pricing from "./pages/pricing"
import Gallery from "./pages/gallery"
import Testing from "./pages/testing"
import Canadaperson from './pages/Canadaperson'
import {BrowserRouter,Routes,Route} from 'react-router-dom'

function App() {
  return (
   <div>
    <BrowserRouter>

<Routes>
  
<Route index element={<Home/>} />
  <Route path="/"  element={<Home/>}/> 
  <Route path="/about" element={<About/>}/>
  <Route path="/services" element={<Services/>}/> 
  <Route path="/projects" element={<Projects/>}/> 
  <Route path="/team" element={<Team/>}/> 
  <Route path="/franchise" element={<Franchise/>}/> 
  <Route path="/contact" element={<Contact/>}/> 
  <Route path="/servicesInfo" element={<ServicesInfo/>}/>
  <Route path='/web' element={<Web/>}/>
      <Route path='/app' element={<Mobile/>}/>
      <Route path='/cloud' element={<Cloud/>}/>
      <Route path='/software' element={<Software/>}/>
      <Route path='/seo' element={<Seo/>}/>
      <Route path='/digital' element={<Digital/>}/>
      <Route path='/graphic' element={<Graphic/>}/>
      <Route path='/content' element={<Content/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/testing' element={<Testing/>}/>
      <Route path='/canadaperson' element={<Canadaperson/>}/>
  {/* <Route path="*"element={<ErrorPage/>}/> */}
  

</Routes>
</BrowserRouter>
   </div>
  );
}

export default App;
