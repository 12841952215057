import React from "react";
import "../styles/Home_services.css";

import features from "../images/features-2.svg";
import { Link } from "react-router-dom";
import Services from "../pages/testing"
function Home_Services() {

  return (
    <div>
      <section id="why-us" class="why-us">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-4 d-flex align-items-stretch"
              data-aos="fade-right"
            >
              <div class="content">
                <h3 class="text-white">
                  Why Choose Tenz Soft for your company website?
                </h3>
                <p class="text-light">
                  Our service offerings enhance customer experience throughout
                  secure & highly functional end-to-end warranty management.
                </p>
                <div class="text-center mb-5">
                  <a href="#" class="more-btn">
                    <Link to="/about" className="text-decoration-none text-white">
                      Learn More
                    </Link>
                    <i class="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-8 d-flex align-items-stretch">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="bx bx-receipt"></i>
                      <h4>Building partnerships that last</h4>
                      <p>
                        We nurture long-term relationships by creating value for
                        our clients in all industries.
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="bx bx-cube-alt"></i>
                      <h4>Creating value</h4>
                      <p>
                        Our world-class team of engineers is ready to take on
                        diverse and challenging projects to create real value
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-xl-4 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div class="icon-box mt-4 mt-xl-0">
                      <i class="bx bx-images"></i>
                      <h4>Proven capability</h4>
                      <p>
                        Give us a problem and we will engineer a solution! We
                        build enterprise and analytical software for every
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          {/* <header class="section-header text-success">
            <p class="text-success">
              Technologies in which we are working on...
            </p>
          </header> */}

          <div class="row">
            <div class="col-lg-6">
              <img src={features} class="img-fluid" alt="" />
            </div>

            {/* <div class="col-lg-6 text-warning  mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">Laravell</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">Wordpress</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3 class="text-success">PHP</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="col-lg-6">
              <header class="section-header ">
                {/* <h2>Features</h2> */}
                <p class="text-warning">
                  Empowering Business for the modern age
                </p>
              </header>
              {/* <h3 class="text-warning">Empowering Business for the modern age</h3> */}
              <p class="p-3">
                Welcome to our cutting-edge software company, where innovation
                meets excellence. At Tenz Soft, we pride ourselves on harnessing
                the power of the latest frameworks and technologies to craft
                solutions that redefine possibilities. Our dynamic team of
                experts leverages state-of-the-art frameworks such as React,
                Angular, and Vue.js to build responsive and intuitive user
                interfaces. Behind the scenes, we employ robust back-end
                technologies like Node.js and Django to ensure seamless
                functionality and scalability. Embracing cloud-native
                architectures and microservices, we create software that adapts
                and scales effortlessly. Join us on the forefront of technology,
                where each line of code is a step towards shaping the future of
                software development. At Tenz Soft, we don't just code; we
                innovate
              </p>
            </div>
          </div>
        </div>
      </section>
      <Services/>
      
      <div>
      {/* <Image src={web} alt="Your Image Alt Text" fluid class="mx-auto" style={{ width: '100%', overflow: 'hidden',height:"70vh" }}/> */}
      {/* Your content goes here if needed */}
    </div>
    </div>
  );
}
export default Home_Services;
