import React from "react";
// import "../styles/services.css";
import { FaRegChartBar, FaTv, FaMobileAlt, FaPeopleArrows, FaChrome, FaAsymmetrik } from 'react-icons/fa';

const servicesData = [
  {
    icon: <FaRegChartBar />,
    title: "Enterprise Software Development",
    description: "Tenz Soft worked with 100+ companies to develop custom enterprise-wide software solutions. We specialize in building precisely what you need and helping you transition from the software you’ve outgrown to the new model.",
  },
  {
    icon: <FaTv />,
    title: "Web App Development",
    description: "Tenz Soft offers custom web app development using a secure, robust codebase that provides the seamless user experience modern users expect.",
  },
  {
    icon: <FaMobileAlt />,
    title: "Mobile App Development",
    description: "Tenz Soft provides modern mobile apps with beautiful designs and intuitive user interfaces. We work with all current mobile technology, including Android and iOS.",
  },
  {
    icon: <FaPeopleArrows />,
    title: "Digital Marketing",
    description: "Promotion of brands to connect with potential customers using the internet and other forms of digital communication.",
  },
  {
    icon: <FaChrome />,
    title: "SEO",
    description: "Process used to optimize a website's technical configuration, content relevance, and link popularity so its pages can become easily findable, more relevant, and popular towards user search queries, and as a consequence, search engines rank them better.",
  },
  {
    icon: <FaAsymmetrik />,
    title: "Graphic Designing",
    description: "Tenz Soft prides itself on its high-quality designers who deliver only the best graphic design services. Find the right design service for you below and get design you'll love today.",
  },
];

const ServicesSection = () => {
  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p className="text-success">
            From startup to enterprise, the full range of engineering services
            your company needs
          </p>
        </header>

        <div className="row gy-4">
          {servicesData.map((service, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay={200 * (index + 1)}
            >
              <div className={`service-box ${getServiceColor(index)}`}>
                <i className="icon">{service.icon}</i>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <p className="read-more">
                  <span>Read More</span> <i className="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Function to get a color based on the index for styling purposes
const getServiceColor = (index) => {
  const colors = ["blue", "orange", "green", "red", "purple", "pink"];
  return colors[index % colors.length];
};

export default ServicesSection;
