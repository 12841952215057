// import React from "react";
// import img from "../images/newhero.png";
// import img from "../images/hero-img.svg";
// import img from "../images/programming.svg";
import img from "../images/rocket.png";
import React from "react"
import {Link} from "react-router-dom"
import "../index.css";
import "../styles/Hero.css"
import { Arrow90degRight, ArrowRight } from "react-bootstrap-icons";
import * as Icon from 'react-bootstrap-icons';
function Hero() {
  const styleLink={
    textDecoration: "none"
  }
    const backgroundImageStyle = {
      backgroundImage: `url(${process.env.PUBLIC_URL}/hero-bg.png)`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '100vh',  // Set the minimum height to cover the entire viewport
      // Add any additional styles you need
    };
  return (
    <>
      <section id="hero" class="d-flex align-items-center" style={backgroundImageStyle}>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1 class="text-light">Welcome to <span class="text-warning">Tenz</span> <span class="text-success">Soft</span></h1>
              <h2 class="text-light">
              Empowering Innovation, Transforming Solutions<br></br> Your IT Partner for a Digital Future

              </h2>
              <div class="d-flex justify-content-center justify-content-lg-start">
                <p>
                <Link to="/contact" style={styleLink} class="btn-get-started scrollto ">
                  Tell us about your project<ArrowRight/>
                </Link>
                </p>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={img} class="img-fluid animated hero-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      
      
    </>
  );
}
export default Hero;
// import React, { useState } from 'react';
// import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const HeroSlider = () => {
//   const [index, setIndex] = useState(0);

//   const handleSelect = (selectedIndex, e) => {
//     setIndex(selectedIndex);
//   };

//   const heroContentStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '5%',
//     transform: 'translateY(-50%)',
//     color: '#fff', // Adjust text color
//     maxWidth: '400px', // Adjust the maximum width of the text box
//   };

//   return (
//     <div className="hero-slider" style={{ position: 'relative' }}>
//       <Carousel activeIndex={index} onSelect={handleSelect}>
//         <Carousel.Item>
//           <div style={heroContentStyle}>
//             <h1>Slide 1</h1>
//             <p>Text for the first slide goes here.</p>
//           </div>
//           <img
//             className="d-block w-100"
//             src="https://placekitten.com/1920/1080"
//             alt="First slide"
//           />
//         </Carousel.Item>

//         <Carousel.Item>
//           <div style={heroContentStyle}>
//             <h1>Slide 2</h1>
//             <p>Text for the second slide goes here.</p>
//           </div>
//           <img
//             className="d-block w-100"
//             src="https://placekitten.com/1920/1081"
//             alt="Second slide"
//           />
//         </Carousel.Item>

//         <Carousel.Item>
//           <div style={heroContentStyle}>
//             <h1>Slide 3</h1>
//             <p>Text for the third slide goes here.</p>
//           </div>
//           <img
//             className="d-block w-100"
//             src="https://placekitten.com/1920/1082"
//             alt="Third slide"
//           />
//         </Carousel.Item>
//       </Carousel>
//     </div>
//   );
// };

// export default HeroSlider;
