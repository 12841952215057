import React from "react"
import img1 from "../images/gallery/tenzsoftsiradrees1.jpeg"
import img2 from "../images/gallery/tenzsoftsiradrees2.jpeg"
import img3 from "../images/gallery/tenzsoftsiradrees3.jpeg"
import img4 from "../images/gallery/tenzsoftsiradrees4.jpeg"
import img5 from "../images/gallery/tenzsoftsiradrees5.jpeg"
import img6 from "../images/gallery/tenzsoftsiradrees6.jpeg"
import img7 from "../images/gallery/tenzsoftsiradrees7.jpeg"
import img8 from "../images/gallery/tenzsoftsiradrees8.jpeg"
import img11 from "../images/gallery/tenzsoftsiradrees11.jpeg"
import img12 from "../images/gallery/tenzsoftsiradrees14.jpeg"
import img13 from "../images/gallery/tenzsoftsiradrees13.jpeg"
import img14 from "../images/gallery/tenzsoftsiradrees15.jpeg"
import img16 from "../images/gallery/tenzsoftsiradrees16.jpeg"
import img17 from "../images/gallery/tenzsoftsiradrees17.jpeg"
import img18 from "../images/gallery/tenzsoftsiradrees18.jpeg"
import img19 from "../images/gallery/tenzsoftsiradrees19.jpeg"
import img20 from "../images/gallery/tenzsoftsiradrees20.jpeg"
import img22 from "../images/gallery/tenzsoftsiradrees22.jpeg"
import img24 from "../images/gallery/tenzsoftsiradrees24.jpeg"
import img25 from "../images/gallery/tenzsoftsiradrees25.jpeg"
import img26 from "../images/gallery/tenzsoftsiradrees26.jpeg"
import img27 from "../images/gallery/siradreestenzsoft27.jpeg"
import img28 from "../images/gallery/siradreestenzsoft28.jpeg"
import img29 from "../images/gallery/siradreestenzsoft29.jpeg"
import img30 from "../images/gallery/siradreestenzsoft30.jpeg"
import img31 from "../images/gallery/siradreestenzsoft31.jpeg"
import img32 from "../images/gallery/siradreestenzsoftlums.jpeg"
import Navbar from "../components/NavBar";
import Footer from "../components/Footer"
// import img2 from "../images/dh.jpg"

function gallery(){
    return(
      <div>
    <Navbar/>
        <section id="portfolio" class="portfolio mt-3">
        <div class="container">
        
          <div class="section-title" data-aos="fade-left">
            <h2>Seminar-Workshops-Awards-Conferences </h2>
          </div>
        
         
        
          <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img12} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img13} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img14} class="img-fluid" alt=""/>
              
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src={img1} class="img-fluid" alt=""/>
              
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img2} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src={img3} class="img-fluid" alt=""/>
              
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src={img4} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img5} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src={img6} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src={img7} class="img-fluid" alt=""/>
               
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src={img8} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img11} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img16} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img17} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img18} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img19} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img20} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img22} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img24} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img25} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img26} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img27} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img28} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img29} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img30} class="img-fluid" alt=""/>
                
              </div>
            </div>
        
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img31} class="img-fluid" alt=""/>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={img32} class="img-fluid" alt=""/>
                
              </div>
            </div>
        

        
          </div>
        
        </div>
        </section>
        <Footer/>
        </div>
    )
}
export default gallery