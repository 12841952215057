import React  from 'react'
import Navbar from "../components/NavBar"
import Footer from "../components/Footer"
import Adrees from "../images/adrees.jpeg"
import * as Icon from "react-bootstrap-icons";

function Canadaperson(){
    return(
        <div>
            <Navbar/>
            <div class="card mt-5  mx-auto" style={{width: "18rem"}}>
  <img src={Adrees} class="card-img-top" alt="..."/>
  <div class="card-body mx-auto">
    <h5 class="card-title">Adrees Chuhdary</h5>
    <p class="card-text"></p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><Icon.Phone class="i  text-success" />:0300-4843092</li>
    <li class="list-group-item">3110 Kingston Rd, Scarborough, ON M1M 1P2, Canada</li>
  </ul>
  <div class="card-body">
    {/* <a href="#" class="card-link">Card link</a> */}
    {/* <a href="#" class="card-link">Another link</a> */}
  </div>
</div>
            <Footer/>
        </div>
    )
  
}
export default Canadaperson