import React from "react";
import features from "../images/skills.png";
import features2 from "../images/why.png";
import asoftlogo from "../images/tenzsoftlogo.png";
import adrees from "../images/adrees.jpeg";
import "../styles/Team.css";

import "../styles/About.css";
import "../index.css";
import NavBar from "../components/NavBar";
import * as Icon from "react-bootstrap-icons";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Link } from "react-router-dom"
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CEO Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>CEO Message</h4> */}
        <p>
          We have the latest information skills/experiences and excellent human
          resources at Tenz Soft. We promote business with our strong spirit that is to
          fulfill customers' requests at all times. Our company also creates
          what customers need to realize glamorous dream they have. As a result,
          we contribute to render an affluent society. These are our motivation.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
const sectionsData = [
  {
    image: adrees,
    alt: "Adrees Chauhdary-CEO",
    headerText: "Adrees Chauhdary-CEO",
    isImageLeft: true,
  },
  {
    headerText: "Welcome to Tenz Soft",
    content: `At Tenz Soft, we are not just a software company; we are architects of innovation and excellence. Our commitment to pushing boundaries and redefining possibilities drives everything we do. In the dynamic landscape of software development, our team stands at the forefront, harnessing the power of cutting-edge frameworks and technologies. We take pride in crafting solutions that transcend expectations, creating a future where innovation meets excellence. Our expertise lies in building responsive and intuitive user interfaces, utilizing state-of-the-art frameworks such as React, Angular, and Vue.js. Behind every seamless user experience, there's a foundation of robust back-end technologies like Node.js and Django. We ensure that our solutions not only meet but exceed the demands of modern, scalable applications. Embracing cloud-native architectures and microservices, we design software that adapts and scales effortlessly. Each line of code is a deliberate step towards shaping the future of software development.`,
    isImageLeft: false,
  },
];
const industriesData = [
  {
    icon: "ri-line-chart-line",
    title: "Technology",
    description: "A full-scale technology and solutions development partner",
  },
  {
    icon: "ri-stack-line",
    title: "Education",
    description: "Educating millions around the world with our e-learning and ed-tech solutions",
  },
  {
    icon: "ri-brush-4-line",
    title: "Business",
    description: "Developing customized solutions for finance clients",
  },
  {
    icon: "ri-magic-line",
    title: "Travel",
    description: "Bringing award-winning travel apps at your fingertips",
  },
  {
    icon: "ri-command-line",
    title: "Ecommerce",
    description: "Delivering custom e-commerce platform for your business",
  },
  {
    icon: "ri-radar-line",
    title: "Health Care",
    description: "Transforming the digital landscape of healthcare",
  },
];
const servicesData = [
  {
    icon: "bx bxl-dribbble",
    iconComponent: <Icon.Dribbble className="i  text-warning" />,
    title: "What we can do?",
    description: "We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.",
  },
  {
    icon: "bx bx-file text-warning",
    iconComponent: <Icon.PeopleFill className="i text-warning" />,
    title: "Our Partners?",
    description: "Our preventive and progressive approach will help you take the lead while addressing possible threats in managing data.",
  },
  {
    icon: "bx bx-tachometer text-warning",
    iconComponent: <Icon.PersonWorkspace className="i text-warning" />,
    title: "Need a hand?",
    description: "Our support team is available 24/7 a day, 7 days a week and can get ready for solving any of your situational rising problems.",
  },
];
const About = () => {
  const FeaturesSection = () => {
    return (
      <div className="features container mt-0 p-0" id="features">
        <div className="row feature-icons mt-4 p-0" data-aos="fade-up">
          <h3 className="text-success">
            We have developed softwares for all industries...
          </h3>
  
          <div className="row mt-3">
            <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
              <img src={features} className="img-fluid p-4" alt="" />
            </div>
  
            <div className="col-xl-8 d-flex content ">
              <div className="row align-self-center gy-4">
                {industriesData.map((industry, index) => (
                  <div key={index} className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={100 * (index + 1)}>
                    <i className={industry.icon}></i>
                    <div>
                      <h4 className="text-black">{industry.title}</h4>
                      <p>{industry.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ServicesSection = () => {
    return (
      <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2 className="text-success">
              From startup to enterprise, the full range of engineering services your company needs.
            </h2>
            <p>We Excel in Delivering Optimal Solution</p>
          </div>
  
          <div className="row">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="col-xl-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100 * (index + 1)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className={service.icon}>
                      {service.iconComponent}
                    </i>
                  </div>
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const divStyle = {
    backgroundColor: "#003651",
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <NavBar />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <section id="about" class="about mt-0 p-0 container">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row gx-0">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="content bg-white mt-5">
                <h2 class=" text-success">
                  Our service offerings enhance customer experience throughout
                  secure & highly functional end-to-end warranty management.
                </h2>
                <p class="fw-fa-italic">
                  Tenz Soft is a prominent software provider that focuses on
                  offering an extensive array of digital services. Thank you for
                  visiting our website. With an enthusiasm for innovation and a
                  dedication to quality, we work hard to become your dependable
                  partner in accomplishing your digital objectives. Tenz Soft
                  has a thorough understanding of the rapidly changing
                  technology world and how it affects enterprises. Our group of
                  gifted experts delivers state-of-the-art solutions customized
                  to your particular requirements by fusing technical know-how
                  with artistic flare. We provide a wide range of services to
                  help you stay ahead in the digital sphere, from web
                  development and app development to SEO, graphic designing, and
                  software development.
                </p>
                <div class="text-center text-lg-start">
                  <p class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center bg-warning">
                  <Link to="/services" class="text-decoration-none"> <span class="text-success">Read More</span></Link> 
                    <i class="bi bi-arrow-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex flex-column justify-content-center align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src={asoftlogo}
                class="img-fluid mx-auto mt-5"
                alt="asoftsolution"
              />

            </div>
          </div>
        </div>
      </section>
<ServicesSection/>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img
                src={adrees}
                class="img-fluid"
                style={{ width: "400px", height: "450px" }}
                alt=""
              />
              <header class="section-header ">
                <p class="text-success">Adrees Chauhdary-CEO</p>
              </header>
            </div>

            <div class="col-lg-6">
              <header class="section-header ">
                <p class="text-warning">Welcome to Tenz Soft</p>
              </header>
              <p class="p-3">
                At Tenz Soft, we are not just a software
                company; we are architects of innovation and excellence. Our
                commitment to pushing boundaries and redefining possibilities
                drives everything we do. In the dynamic landscape of software
                development, our team stands at the forefront, harnessing the
                power of cutting-edge frameworks and technologies. We take pride
                in crafting solutions that transcend expectations, creating a
                future where innovation meets excellence. Our expertise lies in
                building responsive and intuitive user interfaces, utilizing
                state-of-the-art frameworks such as React, Angular, and Vue.js.
                Behind every seamless user experience, there's a foundation of
                robust back-end technologies like Node.js and Django. We ensure
                that our solutions not only meet but exceed the demands of
                modern, scalable applications. Embracing cloud-native
                architectures and microservices, we design software that adapts
                and scales effortlessly. Each line of code is a deliberate step
                towards shaping the future of software development.
              </p>
            </div>
          </div>
        </div>
      </section>
  

      <section id="features" class="features">
        <div class="container" data-aos="fade-up">

          <div class="row">
            <div class="col-lg-6">
              <header class="section-header ">
                <p class="text-warning">
                  Empowering Futures, Connecting Innovations
                </p>
              </header>
              <p class="p-3">
                Set off on an adventure with Tenz Soft, where vision and
                technology combine to create an endless future. Our dedication
                to creativity, teamwork, and client success is demonstrated in
                our About Us section. Our story revolves around a group of
                enthusiastic IT enthusiasts who have one goal in mind: to
                completely transform the digital experience. We are proud of our
                history of innovative solutions and our capacity to lead and
                adjust in a rapidly evolving technology environment. Our story
                is about more than simply the goods and services we offer; it's
                also about the connections we establish, the difficulties we
                take on, and the positive change we hope to bring about. Come
                along on this thrilling journey with us as we reshape the world
                of IT excellence.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={features2} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <div class="features container mt-0 p-0" id="features">
        <div class="row feature-icons mt-4 p-0" data-aos="fade-up">
          <h3 className="text-success">
            We have developed softwares for all industries...
          </h3>

          <div class="row mt-3">
            <div
              class="col-xl-4 text-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={features} class="img-fluid p-4" alt="" />
            </div>

            <div class="col-xl-8 d-flex content ">
              <div class="row align-self-center gy-4">
                <div class="col-md-6 icon-box" data-aos="fade-up">
                  <i class="ri-line-chart-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Technology</h4>
                    <p>
                      A full-scale technology and solutions development partner
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="ri-stack-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Education</h4>
                    <p>
                      Educating millions around the world with our e-learning
                      and ed-tech solutions
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="ri-brush-4-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Business</h4>
                    <p>Developing customized solutions for finance clients</p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="ri-magic-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Travel</h4>
                    <p>Bringing award-winning travel apps at your fingertips</p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="ri-command-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Ecommerce </h4>
                    <p>
                      Delivering custom e-commerce platform for your business
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <i class="ri-radar-line text-warning"></i>
                  <div>
                    <h4 class="text-black">Health Care</h4>
                    <p>Transforming the digital landscape of healthcare</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FeaturesSection/>

      <Footer />
    </div>
  );
};
export default About;
