import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/appservices.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Web() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
              <h2 class="mt-3">
                We Develop Mobile Applications for Native , Hybrid and Cross
                Platforms
              </h2>
            </div>
            <h5 className=" mb-5">
              With Tenz Soft Company's cutting-edge app development services,
              you can stay ahead in the mobile-first world. Your ideas become
              vibrant, user-friendly applications that connect with your target
              audience thanks to the skillful development of our experts.
              Regardless of operating system—Android or iOS—we guarantee that
              your app blends functionality with a smooth user interface to
              produce captivating experiences that promote client loyalty and
              propel business expansion.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img
                src={service}
                class="img-fluid"
                alt="app development service"
              />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Development using Java</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>IOS Developmemt using Swift</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Developmemt using Kotlin</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React Native For Hybrid Application</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flutter For Hybrid Application</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
      <Footer />
    </div>
  );
}
