import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import service1 from "../images/tenzsoftwd.jpg";
import service2 from "../images/tenzsoftdm.jpg";
import service3 from "../images/tenzsoftgd.jpg";
import service4 from "../images/tenzsoftsd.jpg";
const ServiceCard = ({ image, title, description, linkTo }) => {
  return (
    <div className="col-md-6 d-flex" data-aos="fade-right">
      <div className="card">
        <div className="card-img">
          <img src={image} alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title">
            <p className="a text-light">{title}</p>
          </h5>
          <p className="card-text text-light">{description}</p>
          <div className="read-more">
            <p className="a">
              <i className="bi bi-arrow-right"></i>
              <Link
                to={linkTo}
                className="text-decoration-none text-warning"
              >
                Read More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServicesSection = () => {
  const servicesData = [
    {
      image: service1,
      title: 'Web Development',
      description:
        'Boost your online visibility with Tenz Soft knowledgeable web development services. We create fluid, flexible websites that enthrall users and spur business expansion.',
      linkTo: '/web',
    },
    {
      image: service2,
      title: 'Digital Marketing',
      description:
        'Promotion of brands to connect with potential customers using the internet and other forms of digital communication.',
      linkTo: '/digital',
    },
    {
      image: service3,
      title: 'Graphic Designing',
      description:
        "Tenz Soft prides itself on it's high-quality designers who deliver only the best graphic design services. Find the right design service for you below and get design you'll love today.",
      linkTo: '/graphic',
    },
    {
      image: service4,
      title: 'Enterprise Application',
      description:
        "With the help of our excellent software development services, turn your concepts into effective solutions. Tenz Soft is skilled at developing scalable, inventive software that is customized to your particular business requirements.",
      linkTo: '/software',
    },
  ];

  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title">
          <h2 data-aos="fade-in" className="text-success">
            Your company's one-stop engineering shop
          </h2>
          <p data-aos="fade-in">
            Our team of 100+ experienced software engineers, developers, UX
            architects, and designers like to produce impactful software. We
            create software that is used by millions of users, in sectors
            spanning Education, Technology, Healthcare, Travel and beyond. .
          </p>
        </div>

        <div className="row">
          {servicesData.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
