
import React from "react";
import value1 from "../images/values-1.png";
import value2 from "../images/values-2.png";
import value3 from "../images/values-3.png";
import "../styles/values.css";
import { FaRegSmileBeam, FaRegAddressBook, FaHeadphonesAlt, FaPeopleCarry } from 'react-icons/fa';

const valuesData = [
  {
    image: value1,
    title: "What we can do?",
    description: "We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.",
  },
  {
    image: value2,
    title: "Creating Value",
    description: "We have rapidly built a world-class team of engineers ready to take on diverse and challenging projects with an aim to create real value for our partners and community.",
  },
  {
    image: value3,
    title: "Proven Capability.",
    description: "Give us a problem and we will engineer a solution! Our team of world-class engineers has developed enterprise and analytical software used by millions of users every day.",
  },
];

const Values = () => {
  return (
    <section id="values" className="values">
      <div className="container text-dark" data-aos="fade-up">
        <header className="section-header">
          <p className="text-success">Creating Value For Your Business</p>
        </header>
        <div className="row">
          {valuesData.map((value, index) => (
            <div
              key={index}
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-delay={200 * (index + 1)}
            >
              <div className="box">
                <img src={value.image} className="img-fluid" alt="" />
                <h3 className="text-warning">{value.title}</h3>
                <p>{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;
