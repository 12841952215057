import React from "react";
import Hero from "../components/Hero";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import Services from "../components/Home-Services";
import Values from "../components/Values";
import "../styles/Testimonials.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import image from "../images/tenzsofthome1.png"
import Testimonials from "../pages/testimonials"

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <Hero />
      <Services />
      <img src={image} alt="" style={{backgroundColor:"#000f25",width:"100%"}} />
      <Values />
      <Testimonials/>
      <Footer />
    </div>
  );
};
export default Home;
