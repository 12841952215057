import React,{useState} from "react"
import Nav from "../components/NavBar"
import Footer from "../components/Footer"
import { useLocation } from "react-router-dom"
import { useEffect } from 'react'
import value1 from "../images/pakistan.jpg"
import value2 from "../images/england.jpg"
import value4 from "../images/ireland.webp"
import canada from "../images/canada.jpg"
import australia from "../images/australia.jpg"
import newzealand from "../images/newzealand.png"
import * as Icon from "react-bootstrap-icons";
import {Link} from "react-router-dom"
import { Button } from 'react-bootstrap';
import "../styles/franchise.css" ;
import SourcePersonModal from './SourcePersonModal';
const franchiseLocations = [
  { image: value1, location: "40 Commercial, Bolan Block, DC Colony, Gujranwala, Pakistan",phone:"03004843092",link:"" },
  { image: value2, location: "26 High Street, Bilston, WV14 0EP, United Kingdom" ,phone:"",link:"/canadaperson"},
  { image: value4, location: "Prudent Associates, UNIT 6, TRINITY COURT, FONTHILL ROAD, DUBLIN, Dublin 22, D22 YE30, Ireland",phone:"" ,link:""},
  { image: canada, location: "3110 Kingston Rd, Scarborough, ON M1M 1P2, Canada",phone:"",link:"" },
  { image: australia, location: "Sydney, Australia" ,phone:"",link:""},
  { image: newzealand, location: "Auckland, New Zealand",phone:"" ,link:""},
];

function Franchise() {




  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  return (
    <div>
      <Nav />
      <section id="values" className="values">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <p className="text-success">We are working in different countries...</p>
          </header>

          <div className="row">
            {franchiseLocations.map((location, index) => (
              <div key={index} className="col-lg-4" data-aos="fade-up" data-aos-delay={200 * (index + 1)}>
                <div className="box">
                  <img src={location.image} className="img-fluid" alt="" />
                  {/* <h4 className="text-success"style={{ alignSelf: 'flex-end' }} >  <Icon.Phone class="i  text-success" /> :  {location.phone}</h4> */}
                  <h3 className="text-warning">{location.location}</h3>
                  {/* <Link to={location.link} class="text-decoration-none"> <span class="text-success">View Source Person</span></Link>  */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
   
    </div>
  );
}

export default Franchise;
