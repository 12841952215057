import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/tenzsoftwd.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Web() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Nav />
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <header class="container mt-5">
            <div class="section-title" data-aos="fade-up">
              <h2>We Thrive in Web Applications in Modern Technology</h2>
            </div>
            <h5 className="mb-5">
              {/* We use a wide range of proprietary and open-source modern
              technologies and frameworks to produce powerful and user-friendly
              applications that users can access from any modern web browser.
              Nettroller also provides native and cross-platform application
              development services for all browsers with excellent performance
              and a rich user experience. We use a wide range open-source modern
              technologies and frameworks to produce powerful and user-friendly
              applications that users can access from any modern web browser.
              Nettorller also provide native and cross-platform application
              development services for all browsers with excellent performance
              and a rich user experience. */}
              Use the professional web development services offered by Tenz Soft
              Company to fully establish your brand online. Our committed team
              creates visually attractive, user-centric websites by fusing
              creativity and practicality. Our top priority is providing the
              best possible user experience, from responsive design to simple
              navigation. This guarantees that your online presence not only
              attracts but also turns visitors into devoted patrons. Make your
              brand more memorable by creating a website that sticks out in the
              online world.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img
                src={service}
                class="img-fluid mt-4"
                alt="web development service"
              />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Laravel</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Django</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flask</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Php</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>WordPress</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <Footer />
    </div>
  );
}
