import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar.jsx";
import "../styles/Services.css";
import "../index.css";
import features from "../images/features.png";

import servicesImg from "../images/services-img.png";
import Footer from "../components/Footer.jsx";
// import * as Icon from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Services6 from "./services-sub"
const Services = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <NavBar />
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gx-0">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="content">
                <h3 class="text-warning">
                  Tenz <span class="text-success">Soft</span>
                </h3>
                <h2 class="text-success">
                  Tenz Soft love to build and refine technologies that improve
                  lives while developing great relationships along the way.
                </h2>
                <p>
                  Tenz Soft work on cutting-edge technology and enterprise
                  solutions for solving real-world problems and help businesses
                  become bigger & better.
                </p>
                <div class="text-center text-lg-start">
                  <Link to="/contact">
                    <span class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                      Tell us more about your project!
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src={servicesImg}
                class="img-fluid"
                alt="Best Software house in Gujranwala "
              />
            </div>
          </div>
        </div>
      </section>
      <Services6/>
     
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Features</h2> */}
            <p class="text-success">
              Technologies in which we are working on...
            </p>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={features} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Laravell</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Wordpress</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>PHP</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Services;
